import React, { useState, useEffect } from "react";
import ProductForm from "./components/ProductForm/ProductForm";
import ProductList from "./components/ProductList/ProductList";
import Header from "./components/Header";
import ExampleSearches from "./components/ExampleSearches";
import Spinner from "./components/Spinner/Spinner";
import { v4 as uuidv4 } from "uuid";
import ReactGA from 'react-ga';

function App() {
  const [recommendations, setRecommendations] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialQueryDone, setInitialQueryDone] = useState(false);

  // Function to generate a new session ID
  const generateNewSessionId = () => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  };

  useEffect(() => {
    // Initialize Google Analytics and track page view
    ReactGA.initialize('G-5QCLJ38RT2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Generate a unique session ID on initial load
    generateNewSessionId();
  }, []);

  const handleNewSearch = () => {
    // Reset the state for a new search
    setRecommendations([]);
    setSearchCompleted(false);
    setInitialQueryDone(false);

    // Generate a new session ID for the new search
    generateNewSessionId();

    // Track new search event
    ReactGA.event({
      category: 'User',
      action: 'Started New Search'
    });
  };

  const handleSearchComplete = () => {
    // Mark the search as completed
    setSearchCompleted(true);
    setInput("");
    setInitialQueryDone(true);

    // Track search complete event
    ReactGA.event({
      category: 'User',
      action: 'Search Completed'
    });
  };

  return (
    <div className="App" style={styles.app}>
      <Header handleNewSearch={handleNewSearch} />
      <Spinner isLoading={isLoading} />
      <div style={styles.content}>
        <ProductForm
          setRecommendations={setRecommendations}
          sessionId={sessionId}
          onSearchComplete={handleSearchComplete}
          input={input}
          setInput={setInput}
          setIsLoading={setIsLoading}
          handleNewSearch={handleNewSearch}
        />
        <ProductList recommendations={recommendations} sessionId={sessionId} />
      </div>
    </div>
  );
}

const styles = {
  app: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    backgroundColor: "#f4f4f9",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center contents vertically
  },
  header: {
    marginLeft: "20px",
    color: "#333",
  },
  newSearchButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  content: {
    marginTop: "80px", // Ensure content is not hidden under the header
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default App;
