// src/components/ProductList.js
import React, { useState, useEffect } from "react";
import "./ProductList.css";

const ProductList = ({ recommendations, sessionId }) => {
  const [loading, setLoading] = useState(false);
  const [topChoiceIndex, setTopChoiceIndex] = useState(null);
  const [summaries, setSummaries] = useState({});

  useEffect(() => {
    if (recommendations.length > 0) {
      const randomIndex = Math.floor(Math.random() * recommendations.length);
      setTopChoiceIndex(randomIndex);
    }
  }, [recommendations]);

  useEffect(() => {
    const fetchSummaries = async () => {
      console.log("Initializing WebSocket connection...");
      const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws/summaries`);
      ws.onopen = () => {
        console.log("WebSocket connection opened.");
        ws.send(sessionId);
      };
      ws.onmessage = (event) => {
        const data = event.data;
        console.log(`Received message: ${data}`);
        const [productIdPart, summaryPart] = data.split(", Summary: ");
        const productIdMatch = productIdPart.match(/Product ID: ([\w-]+)/);
        if (productIdMatch && summaryPart) {
          const productId = productIdMatch[1];
          let summary = summaryPart.trim();
          console.log(`Summary for product ${productId}: ${summary}`);
          setSummaries((prevSummaries) => ({
            ...prevSummaries,
            [productId]: summary,
          }));
        } else {
          console.error("Failed to parse product ID and summary from message.");
        }
      };
      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      ws.onclose = () => {
        console.info("WebSocket connection closed.");
      };

      // Cleanup function to close WebSocket connection
      return () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.info("WebSocket connection closed by cleanup.");
        }
      };
    };

    if (recommendations.length > 0) {
      const cleanup = fetchSummaries();
      return () => {
        if (cleanup && typeof cleanup === 'function') {
          cleanup();
        }
      };
    }
  }, [recommendations]);

  const handleViewProduct = async (productId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_link?session_id=${sessionId}&product_id=${productId}`);
      const data = await response.json();
      if (data.additional_link) {
        window.open(data.additional_link, '_blank');
      } else {
        alert("Failed to retrieve product link.");
      }
    } catch (error) {
      console.error("Error fetching product link:", error);
      alert("An error occurred while fetching the product link.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      {recommendations.length > 0 && <h3 className="resultsTitle">Search Results:</h3>}
      <div className="container">
        {recommendations.length > 0 && (
          recommendations.map((product, index) => (
            <div key={index} className="product">
              {/* {index === topChoiceIndex && (
                <div className="topChoiceTag">Top choice</div>
              )} */}
              <h4>{product.title}</h4>
              {product.image && (
                <img src={product.image} alt={product.title} className="image" />
              )}
              <p className="price">{product.price}</p>
              <div className="summary">
                {summaries[product.id] && summaries[product.id] !== "no content" && !summaries[product.id].endsWith("ack") && (
                  <ul>
                    {summaries[product.id].split("\n").map((item, idx) => item && <li key={idx}>{item.replace(/^- /, '')}</li>)}
                  </ul>
                )}
              </div>
              <button 
                onClick={() => handleViewProduct(product.id)} 
                className="link"
                disabled={loading}
              >
                View Product
              </button>
              <a 
                href={`https://www.google.com/search?tbm=shop&q=${encodeURIComponent(product.title)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="googleShoppingLink"
              >
                Search Google Shopping
              </a>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProductList;
