import React from "react";
import "./Spinner.css"; // Import the CSS file for spinner animation

const Spinner = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.spinnerContainer}>
        <div className="spinner"></div> {/* Use className for spinner */}
        <p style={styles.message}>Searching... This might take a few seconds.</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    marginTop: "10px",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#333",
  },
};

export default Spinner;
