import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaSearch, FaQuestionCircle } from "react-icons/fa";
import debounce from "lodash.debounce";
import ReactMarkdown from "react-markdown";
import "./ProductForm.css";

const ProductForm = ({ setRecommendations, sessionId, onSearchComplete, input, setInput, setIsLoading }) => {
  const [specificSite, setSpecificSite] = useState("");
  const [numLinks, setNumLinks] = useState(6);
  const [numRecommendations, setNumRecommendations] = useState(6);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [searchInput, setSearchInput] = useState(input);
  const [suggestions, setSuggestions] = useState([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [response, setResponse] = useState("");
  const [isEscPressed, setIsEscPressed] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);
  const fetchSuggestionsRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    if (input) {
      setSearchInput(input);
      handleSearch(input);
    }
  }, [input]);

  const resetStates = () => {
    setSearchInput(""); // Clear the input field
    setSuggestions([]); // Remove the suggestions
    setIsOutsideClicked(false); // Reset the outside click state
  };

  const handleSearch = async (searchValue) => {
    setIsLoading(true);
    setIsLoadingLocal(true);
    setIsFormSubmitted(true);
    setIsEscPressed(false); // Reset the esc press state

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
        params: {
          user_input: searchValue.trim(),
          num_links_to_scan: numLinks,
          num_recommendations: numRecommendations,
          specific_site: specificSite,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      setRecommendations(response.data.recommendations);
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setResponse(""); // Reset the question response
    } catch (error) {
      console.error("Error fetching product recommendations:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingLocal(false);
      setIsFormSubmitted(false);
      setIsEscPressed(false); // Reset the esc press state after search
    }
  };

  const handleQuestionSubmit = async (searchValue) => {
    setIsLoading(true);
    setIsLoadingLocal(true);
    setIsEscPressed(false); // Reset the esc press state

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/question`, {
        params: {
          question: searchValue.trim(),
          session_id: sessionId
        },
      });
      setResponse(res.data.answer);
      setSearchInput("");
    } catch (error) {
      console.error("Error submitting question:", error);
      setResponse("An error occurred while submitting your question.");
    } finally {
      setIsLoading(false);
      setIsLoadingLocal(false);
      setIsEscPressed(false); // Reset the esc press state after question submission
    }
  };

  const fetchSuggestions = async (value) => {
    if (isFetchingSuggestions || isEscPressed) return;
    setIsFetchingSuggestions(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/suggestions`, {
        params: {
          partial_input: value,
          num_suggestions: 5,
        },
      });
      if (!isFormSubmitted) {
        setSuggestions(response.data.suggestions || []); // Ensure suggestions is an array
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]); // Set suggestions to an empty array on error
    } finally {
      setIsFetchingSuggestions(false);
    }
  };

  const debouncedFetchSuggestions = useRef(debounce(fetchSuggestions, 300)).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    // Reset the outside click state when user types something
    setIsOutsideClicked(false);

    if (value.length === 0) {
      setIsEscPressed(false); // Reset the esc press state when input is cleared
    }

    if (value.length > 0 && !isEscPressed) {
      debouncedFetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
    handleSearch(suggestion); // Confirm that the suggestion will be used as the product to search for
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchInput);
    resetStates(); // Reset states after form submission
  };

  const handleQuestionButtonClick = (e) => {
    e.preventDefault();
    handleQuestionSubmit(searchInput);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.inputGroup')) {
      setSuggestions([]);
      setIsOutsideClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSuggestions([]);
      setIsEscPressed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleShowMeClick = () => {
    handleSearch("Show me products based on the last question I asked.");
  };

  return (
    <form onSubmit={handleFormSubmit} className="form">
      <div className="inputGroup">
        <label className="label">
          <FaSearch className="icon" /> Find a Product:
        </label>
        <p className="description">Enter the product you're looking for or ask a question.</p>
        <input
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          className="input"
          required
        />
        {searchInput.length > 0 && suggestions.length > 0 && !isLoadingLocal && !isEscPressed && (
          <ul className="suggestionsList">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestionItem"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="buttonGroup">
        <button type="submit" className="button" style={{ marginRight: "5px" }} disabled={isLoadingLocal}>Search</button>
        <button onClick={handleQuestionButtonClick} className="button" disabled={isLoadingLocal}>Ask Question</button>
      </div>
      {response && (
        <div className="response-container">
          <ReactMarkdown>{response}</ReactMarkdown>
          <button onClick={handleShowMeClick} className="button" style={{ marginBottom: "15px" }}>Show me</button>
        </div>
      )}
    </form>
  );
};

export default ProductForm;
